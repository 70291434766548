import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Gallery from "./Gallery"

const Photos = () => {
  const { wildlife } = useStaticQuery(graphql`
    query {
      wildlife: contentfulGallery(name: { eq: "Wildlife" }) {
        images {
          contentful_id
          fluid(maxWidth: 600, quality: 100) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
    }
  `)
  return <Gallery data={wildlife} subHeader="WILDLIFE" id="big5"></Gallery>
}

export default Photos

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Gallery from "./Gallery"

const Photos = () => {
  const { tents } = useStaticQuery(graphql`
    query {
      tents: contentfulGallery(name: { eq: "Tents" }) {
        images {
          contentful_id
          fluid(maxWidth: 600, quality: 100) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
    }
  `)
  return (
    <Gallery
      data={tents}
      subHeader="SAFARI-STYLE TENTED ACCOMMODATION"
      id="tents"
    ></Gallery>
  )
}

export default Photos
